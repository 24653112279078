import React from 'react';
import s from './EmbassyAttestation.module.css'

const EmbassyAttestation = () => {

    return (
        <div className={s.crypto_wrapper} id={'5'}>

            <div className={s.title}>
                Embassy Attestation
                What we do
            </div>
            <div className={s.desc}>
                Customers taking documents to countries that are not fully
                <a href="https://www.apostille.org.uk/hague-convention"> signed up to the Hague Convention</a>  find that
                their documents need to be legalised or attested with the consulate or embassy in Limassol. With years
                of experience <a href="https://www.apostille.org.uk/attestation">legalising documents with embassies </a>
                we know how to submit documents on your behalf through all the stages.
            </div>

        </div>
    );
};

export default EmbassyAttestation;
