import React from 'react';
import s from "./Translations.module.css";
import people from "../../assets/img/translation.webp";

const Translations = () => {
    return (
        <div id="6" className={s.bank_account_opening_wrapper}>
            <img className={s.people} src={people} alt=""/>
            <div className={s.text_block}>
                <div className={s.title}>Translation Services</div>
                <div className={s.description}>
                    We can translate your documents into more than 140 different languages and we can do this at the same
                    time as we process any apostille services to try and save you some time. If you need a translation
                    please contact us by email with copies of your documents so we can assess the charge and time required
                    to translate the documents.
                </div>
                <div className={s.description}>
                    We can provide a translation service even if we are not legalising any documents for you.
                </div>
                <div className={s.description}>
                    Translations are often requested by authorities outside of the Cyprus when you are supplying legalised
                    documentation. The translation helps the overseas authority or person to understand the language
                    source documents that have been legalised.
                </div>
                <div className={s.description}>
                    We can arrange to translate just the documents or we can translate the apostille certificate as well.
                    If you are not sure what is required please check with however is asking you to legalise the documents
                    as it is them that must accept the final documentation from you.
                </div>
            </div>
        </div>
    );
};

export default Translations;
