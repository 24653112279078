import s from './App.module.css';
import Header from "./Components/header/Header";
import Main from "./Components/main/Main";
import CertifyingOfficer from "./Components/certifyingOfficer/CertifyingOfficer";
import Apostille from "./Components/Apostille/Apostille";
import CompanyRegistar from "./Components/CompanyRegistar/CompanyRegistar";
import EmbassyAttestation from "./Components/EmbassyAttestation/EmbassyAttestation";
import ContactUs from "./Components/contact/ContactUs";
import Footer from "./Components/footer/Footer";
import Translations from "./Components/Translations/Translations";

function App() {

    return (
        <div className={s.app}>
            <Header/>
            <Main/>
            <CertifyingOfficer/>
            <Apostille/>
            <CompanyRegistar/>
            <EmbassyAttestation/>
            <Translations/>
            <ContactUs/>
            <Footer/>
        </div>
    );
}

export default App;
