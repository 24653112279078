import React, {useState} from 'react';
import s from './ContactUs.module.css'
import {API} from '../../api/api';
import MyPopup from '../UI/MyPopup/MyPopup';
import closePopup from '../../assets/img/icon_close_popup.png'

const ContactUs = () => {

    const [data, setData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
    })
    const [visible, setVisible] = useState(false)
    const [error, setError] = useState(false)

    const dataForSend = {
        project: "savvalimassol",
        subject: "",
        data: data
    }

    const sendMessage = async () => {

        if (data.message.length === 0 || data.lastName.length === 0 || data.email.length === 0 || data.phone.length === 0 || data.firstName.length === 0) {
            setError(true)
            return
        }

        let response = await API.send(dataForSend);
        if (response.status === 200) {
            setVisible(true)
            setError(false)
            setData({
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                message: '',
            })
        }
    }

    return (
        <div>
            <div className={s.contact_wrapper} id={'7'}>
                <div className={s.contact_content}>

                    <div className={s.title}>
                        contact
                    </div>
                    <div className={s.subtitle}>
                        Full Client Confidentiality.
                    </div>
                    <div className={s.form_wrapper}>
                        <div className={s.inputs_block}>
                            <div>
                                <input type="text"
                                       className={`${s.input} ${s.mr}`}
                                       placeholder={'First name'}
                                       onChange={e => setData({...data, firstName: e.target.value})}
                                       value={data.firstName}
                                />
                                <input type="text"
                                       className={`${s.input} ${s.mr}`}
                                       placeholder={'Last name'}
                                       onChange={e => setData({...data, lastName: e.target.value})}
                                       value={data.lastName}
                                />
                            </div>
                            <div>
                                <input type="text"
                                       className={s.input}
                                       placeholder={'E-mail address'}
                                       onChange={e => setData({...data, email: e.target.value})}
                                       value={data.email}
                                />
                                <input type="text"
                                       className={s.input}
                                       placeholder={'Phone number'}
                                       onChange={e => setData({...data, phone: e.target.value})}
                                       value={data.phone}
                                />
                            </div>
                        </div>
                        <div className={s.textarea_block}>
                        <textarea
                            className={s.textarea}
                            placeholder={'Your message'}
                            onChange={e => setData({...data, message: e.target.value})}
                            value={data.message}
                        />
                        </div>
                        <div className={s.btn_wrapper}>
                            <div>
                                <button className={s.my_btn} onClick={sendMessage}>
                                    submit
                                </button>
                            </div>

                            <div className={s.error}>
                                {error
                                    ? <span>All fields must be filled</span>
                                    : <></>
                                }
                            </div>
                        </div>


                    </div>
                </div>

                <div className={s.contactsData}>
                    <div className={s.contacts_text}>email: info@savvalimassol.com </div>
                    <div className={s.contacts_text}>tel: +357 96295340</div>
                    <div className={s.contacts_text}>Address: 113th St 9, Kato Polemidia 4130, Limassol, Cyprus</div>
                </div>

                <MyPopup visible={visible} setVisible={setVisible}>
                    <div className={s.close}>
                        <img src={closePopup} alt=""
                             onClick={() => {setVisible(false)}}/>
                    </div>
                    <div className={s.popup_content_wrapper}>
                        <div className={s.popup_title}>
                            success!
                        </div>
                        <div className={s.popup_subtitle} >
                            Your MESSAGE has been SENT
                        </div>
                        <div className={s.popup_text}>
                            Our specialist will contact you soon
                        </div>
                        <button className={s.popup_btn}
                                onClick={() => {setVisible(false)}}>
                            ok
                        </button>
                    </div>
                </MyPopup>

            </div>

            <div className={s.mapWrapper}>
                {<iframe
                    src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3280.2222050095384!2d32.99184791559399!3d34.69957499078553!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14e733d9683d18cb%3A0xcce83f445264d02d!2s113th%20St%209%2C%20Kato%20Polemidia%2C%20Cyprus!5e0!3m2!1sen!2sby!4v1675945277624!5m2!1sen!2sby"}
                    width="1200" height="600" style={{border:0}} allowFullScreen="" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>}
            </div>

        </div>

    );
};

export default ContactUs;
