import React, {useState} from 'react';
import s from './Header.module.css'
import logo from '../../assets/img/savva_maria_logo.png'
import stamp from '../../assets/img/savva_maria_stamp.png'
import burger from '../../assets/img/hamburger.png'
import close from '../../assets/img/icon_close.png'
import {Link} from "react-scroll";
import facebook from "../../assets/img/icon_facebook.png";
import insta from "../../assets/img/icon_instagram.png";
import linkedin from "../../assets/img/icon_linkedin.png";

const Header = () => {

    const [navIsShow, SetNavIsShow] = useState(false)

    return (
        <div>
            <div className={s.header_wrapper_web}>
                <div className={s.header}>
                    <div className={s.logo}>
                        <div>
                            <img src={logo} alt=""/>
                            <img className={s.stamp} src={stamp} alt=""/>
                        </div>
                        <div className={s.contacts}>
                            <div className={s.contactsData}>
                                E-Mail: info@savvalimassol.com
                            </div>
                            <div className={s.contactsData}>
                                TEL: +357 96295340
                            </div>
                        </div>
                    </div>
                    <nav className={s.nav}>
                        <Link
                            activeClass={s.active}
                            to="1"
                            spy={true}
                            smooth={true}
                            offset={-150}
                            duration={500}
                        >HOME</Link>
                        <Link
                            activeClass={s.active}
                            to="2"
                            spy={true}
                            smooth={true}
                            offset={-150}
                            duration={500}
                        >CERTIFYING OFFICER</Link>
                        <Link
                            activeClass={s.active}
                            to="3"
                            spy={true}
                            smooth={true}
                            offset={-150}
                            duration={500}
                        >APOSTILLE</Link>
                        <Link
                            activeClass={s.active}
                            to="4"
                            spy={true}
                            smooth={true}
                            offset={-150}
                            duration={500}
                        >COMPANY REGISTAR</Link>
                        <Link
                            activeClass={s.active}
                            to="5"
                            spy={true}
                            smooth={true}
                            offset={-150}
                            duration={500}
                        >EMBASSY ATTESTATION</Link>
                        <Link
                            activeClass={s.active}
                            to="6"
                            spy={true}
                            smooth={true}
                            offset={-150}
                            duration={500}
                        >TRANSLATIONS</Link>
                        <Link
                            activeClass={s.active}
                            to="7"
                            spy={true}
                            smooth={true}
                            offset={-30}
                            duration={500}
                        >CONTACT US</Link>
                    </nav>
                </div>
            </div>
            <div className={s.header_wrapper_mobile}>
                <Link
                    to="1"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                >

                </Link>

                <div className={s.hamburger_menu_block}>
                    <img src={burger} alt="" onClick={() => SetNavIsShow(true)}/>
                </div>
                <div className={navIsShow ? s.mobile_nav : s.none}>
                    <div className={s.mobile_nav_content}>
                        <div className={s.close_block}>
                            <img src={close} alt="" onClick={() => SetNavIsShow(false)}/>
                        </div>
                        <div className={s.mobile_nav_link_wrapper}>
                            <Link
                                activeClass={s.active_mobile}
                                to="1"
                                spy={true}
                                smooth={true}
                                offset={0}
                                duration={500}
                            >Home</Link>
                            <Link
                                activeClass={s.active_mobile}
                                to="2"
                                spy={true}
                                smooth={true}
                                offset={10}
                                duration={500}
                            >Certifying Officer</Link>
                            <Link
                                activeClass={s.active_mobile}
                                to="3"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                            >Apostille</Link>
                            <Link
                                activeClass={s.active_mobile}
                                to="4"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                            >Company Registar</Link>
                            <Link
                                activeClass={s.active_mobile}
                                to="5"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                            >Embassy Attestation</Link>
                            <Link
                                activeClass={s.active_mobile}
                                to="6"
                                spy={true}
                                smooth={true}
                                offset={40}
                                duration={500}
                            >Translations</Link>
                            <Link
                                activeClass={s.active_mobile}
                                to="7"
                                spy={true}
                                smooth={true}
                                offset={40}
                                duration={500}
                            >Contact Us</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
};

export default Header;
