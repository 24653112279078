import React from 'react';
import s from "./CompanyRegistar.module.css";
import people from "../../assets/img/companyRegistrar.webp";

const CompanyRegistar = () => {
    return (
        <div>
            <div id={'4'} className={s.bank_account_opening_wrapper}>

                <div className={s.text_block}>
                    <div className={s.title}>Company Registar</div>
                    <div className={s.subtitle}>Aim</div>
                    <div className={s.description}>
                        The main aim of is to provide our customers with certified copies of there companies
                        certification
                        from the Department of Registrar of Companies and Official Receiver in Cyprus, discreetly,
                        efficiently,
                        effectively at a low cost.
                    </div>
                    <div className={s.title}>
                        What we can do
                        <ul>
                            <li className={s.list_item}>
                                Obtain Certified copies of your Companies Certification.
                            </li>
                            <li className={s.list_item}>
                                Apostille of the Certified Documents.
                            </li>
                            <li className={s.list_item}>
                                Deliver them to your office or we can send them to you all over the world.
                            </li>
                            <li className={s.list_item}>
                                Same Business day Service available.
                            </li>
                            <li className={s.list_item}>
                                Pick up and delivery service is available.
                            </li>
                        </ul>
                    </div>
                </div>
                <img src={people} className={s.people} alt=""/>
            </div>
        </div>
    );
};

export default CompanyRegistar;
