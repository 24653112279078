import axios from 'axios';

const settings = {
    headers : {'Content-type' : 'application/json'},

}

const instance = axios.create({
    baseURL : 'https://assembly.alexandriabancorp.ltd/',
    mode: 'no-cors',
    credentials: 'same-origin',
    ...settings
})

export const API = {
    send(data) {
        return instance.post(`api/send_form/from`, data, settings)
    },
}
