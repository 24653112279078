import React from 'react';
import s from './Apostille.module.css'
import people from '../../assets/img/apostile.webp'

const Apostille = () => {
    return (
        <div id={'3'} className={s.bank_account_opening_wrapper}>
            <img src={people} alt="" className={s.people}/>
            <div className={s.text_block}>
                <div className={s.title}>Apostille Service</div>
                <div className={s.subtitle}>What is an Apostille?</div>
                <div className={s.description}>
                    Pronounced “ah-po-steel” is a French word meaning certification. <br/>
                    An Apostille is simply the name for a specialized certificate, issued by the Ministry of Justice.
                    The Apostille is attached to your original document to verify it is legitimate and authentic so it
                    will be accepted in one of the other countries who are members of the Hague Apostille Convention.
                </div>
                <div className={s.title}>
                    What we can do
                    <ul>
                        <li className={s.list_item}>
                            Pick up the document from your office and deliver it back to you the same day with the
                            Apostille
                            seal or you can come to our office with your document and then come and pick it up the same
                            day.
                        </li>
                        <li className={s.list_item}>
                            If your document was issued in a foreign country member of the Hague Apostille Convention
                            and it has to have the seal of the Apostille from the country of issuance we can do that for
                            you through agent across the world
                        </li>
                        <li className={s.list_item}>
                            In the cases of private documents where the signature has to be certified we do that for
                            you through our Certifying Officer Savvas Demetriou.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Apostille;
