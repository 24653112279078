import React from 'react';
import s from './MyPopup.module.css'

const MyPopup = ({children, visible, setVisible}) => {

    const rootClasses = [s.myModal]

    if (visible) {
        rootClasses.push(s.active)
    }

    return (
        <div className={rootClasses.join(' ')} >
            <div className={s.myModalContent} onClick={(e) => e.stopPropagation()}>
                {children}
            </div>
        </div>
    );
};

export default MyPopup;
