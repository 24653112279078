import React from 'react';
import s from './CertifyingOfficer.module.css'

const CertifyingOfficer = () => {
    return (
        <div className={s.formation_wrapper} >
            <div className={s.formation_content} id={'2'}>
                <div className={s.title}>
                    About
                </div>
                <div className={s.text}>
                    Savva Maria was appointed by the Minister of Internal Affairs, of the Republic Cyprus,
                    in 2012 as a Certifying Officer of the Limassol, Republic of Cyprus.
                </div>
                <div className={s.title}>
                    Signature Authentication offered for:
                </div>
                <div className={s.text}>
                    <ul>
                        <li>Power of Attorneys</li>
                        <li>Agreements</li>
                        <li>Rental Contracts</li>
                        <li>Vehicle Transfers</li>
                        <li>Boat Transfers</li>
                        <li>Travelling Consents</li>
                        <li>Bank Agreement</li>
                        <li>Government forms & Document</li>
                    </ul>
                </div>
            </div>

        </div>
    );
};

export default CertifyingOfficer;
