import React from 'react';
import s from './Footer.module.css'

const Footer = () => {
    return (
        <div>
            <div className={s.footer_wrapper_web}>
                <div className={s.footer_container}>
                    <div className={s.footer_content}>
                        <div>
                            Apostille Services-Antmargar Limited
                        </div>
                        <div>
                            Apostille-Company Registrar-Embassy Attestation-Certifying Officer
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Footer;
