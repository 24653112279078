import React from 'react';
import s from './Main.module.css'

const Main = () => {
    return (
        <div className={s.main_wrapper} id={'1'}>
            <div className={s.overlay}></div>
            <div className={s.main_container}>
                <div className={s.content}>
                    <div className={s.title}>
                        <div>CERTIFYING OFFICER IN LIMASSOL</div>
                        <div>ΠΙΣΤΟΠΟΙΩΝ ΥΠΑΛΛΗΛΟΣ ΣΤΗΝ ΛΕΜΕΣΟ</div>
                        <div>NOTARY & APOSTILLE SERVICES IN LIMASSOL</div>
                    </div>
                    <div className={s.subtitle}>
                        Savva Maria
                    </div>
                    <div className={s.btn_block}>
                        <button className={s.myBtn}>
                            Book your appointment now
                        </button>
                    </div>
                    <div className={s.effective_block}>
                        Cost Effective - Discreet - Same day Service
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Main;
